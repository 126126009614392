
$lightgray: #7c91ae;
$border-color: #9bb4c1;

.is-special {
  width: 100%;
  padding: 0.5rem 0 1rem;
  overflow: auto;
  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }
}

.nickname-wrap {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: stretch;

  .nickname-group {
    width: 100%;

    input {
      flex: 1;
    }
  }

  .contract-start {
    width: 200px;

    .action {
      // height: 100%;
      height: 2.285em;

      :deep() {
        input {
          height: 100%;
        }
      }
    }
  }
}

.tss-id {
  display: flex;
  align-items: center;

  button {
    margin-left: 7px;
  }
}

#btn-register {
  margin: 1em 0;
}

.back {
  margin-top: 10px;
  min-width: 90px;
  height: 30px;
}
