
.columns {
  padding-left: 10px;
}

td.is-narrow {
  width: 120px !important;
}

th.is-narrow {
  width: 120px !important;
}

$color--dark-gray: #7c91ae;

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }
}
