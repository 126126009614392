
#category-form {
  .name {
    margin: 0 0 20px 0;
  }

  .cat-hidden {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .lbl {
      margin-right: 10px;
    }
  }

  .action {
    margin: 30px 0 0 0;
    display: flex;
    gap: 10px;
  }
}
