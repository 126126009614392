
#candidate-holiday-request {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .table-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;

      .balance {
        font-weight: bold;
        border: 1px solid silver;
        padding: 5px;
        font-size: 1.2rem;
      }
    }
  }

  .pagination {
    align-self: flex-end;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    label {
      text-align: left;
      margin-right: auto;
    }

    input {
      height: 40px;
    }

    .multiselect {
      width: 220px;
    }
  }
}
