
.title.is-3 {
  margin-top: 25px;
}
span.sep {
  border: 1px solid #d6e0e5;
  margin: 0 0.8em;
}

.tag {
  border-radius: 3px;
}

table {
  border-radius: 4px;

  &.is-fixed {
    table-layout: fixed;
    border-collapse: collapsed;
  }
}

.comment-box {
  margin-bottom: 1em;
}

tbody {
  .one-td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  th {
    height: 50px;
    border: 1px solid #b2cddb;
    background-color: #eff5f5;
  }

  th,
  td {
    width: 50%;
    vertical-align: middle;
  }

  .cell-wide {
    width: 80%;
  }

  td.cell-wide {
    color: grey;
  }

  .generic-app-tag {
    background-color: #dbe1e5;
    border-radius: 3px;
    padding: 0.2em 0.5em;
  }
}
