
.dialog-container {
  width: 100%;
  overflow: hidden;

  .form-input-wrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    // padding: 0 1.3em;

    label {
      margin-top: 0;
    }
  }
}

.control {
  margin: 0.75rem 0 0 0 !important;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

table.no-border {
  border: 0px;
}

.btn-loader {
  height: 18px;
  padding-right: 4px;
}

.client-reference-id {
  display: flex;
  gap: 5px;
  align-items: flex-end;

  .control:first-child {
    width: 100%;
  }

  button {
    // margin-bottom: 0.75rem;
    height: 2.285em;
  }
}

.help {
  margin: 0 0 10px 0;
}
