
.page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;

  .sides {
    display: flex;
    gap: 20px;
    justify-content: center;
    height: 100%;

    > * {
      flex-basis: 50%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .wrapper-category {
      height: 100%;
      overflow: auto;
    }
  }
}
