
.multiple-wrap {
  display: flex;
  flex-direction: column;
  gap: 2em 0;
  max-height: 50vh;
  overflow: auto;

  .control {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    white-space: nowrap;
  }

  .buttons-centered {
    position: sticky;
    bottom: 0;
    left: 0;
    background: #fff;
  }

  .table-wrap {
    display: flex;

    .table {
      width: 100%;
    }
  }
}

.shift {
  display: flex;
  flex-direction: column;
  gap: 0.5em 0;

  .row {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    width: 100%;

    .control.error {
      color: red;
    }

    .control.success {
      color: green;
    }

    .field {
      width: 50%;
    }
  }
}

.is-suffix {
  white-space: nowrap;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.date-time {
  padding: 6px;
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  border: 1px solid #e2e7ea !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
  display: inline-block;
  white-space: nowrap;
  font-size: 15px;
  pointer-events: none;
  user-select: none;
}
