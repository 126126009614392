
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--border: #9bb4c1;
.preview-table {
  margin-bottom: 35px;
}
.color-svg {
  width: 30px;
  margin: 0 15px 0 0;
  height: 30px;
  :deep() {
    path {
      fill: black;
    }
  }
}

tbody {
  tr {
    height: 60px;
  }
}
.recalculationInProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.divider {
  padding: 25px 0 45px 0;
  border-top: 2px solid $color--light-gray;
  min-height: 50px;
  .progres-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 10px 0 10px;
    }
  }
  &.below {
    .label {
      margin: 0 5px;
    }
  }
}

.input {
  border: 1px solid $color--light-gray;
  height: 37px;
  width: 200px;

  &::placeholder {
    color: $color--light-blue;
    font-size: 15px;
  }
}
.inputId{
  width: 100%;
}

.control {
  i {
    top: 10px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

.filter {
  .control {
    min-width: 250px;
    max-width: 500px;

    i {
      position: absolute;
      right: 0;
      top: 0;
    }

    .label {
      margin-left: 5px;
    }

    .status,
    .subcat {
      display: flex;
      align-items: center;

      .info {
        border-radius: 50%;
        border: 1px solid silver;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 27px;
        min-height: 27px;
        width: 27px;
        height: 27px;

        .fa {
          color: #7c91ae;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  .date-dropdown {
    width: 180px;
  }
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  label {
    margin: 0 1em;
  }
}

.nivelate {
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.interval {
  .field {
    float: right;
  }
}

.label {
  color: #7c91ae;
}

.help {
  .is-danger {
    position: relative;
    left: 150px;
    margin-bottom: 20px;
  }
}

.error {
  width: 100%;
  text-align: right;

  p {
    margin-right: 150px;
    margin-bottom: 10px;
  }
}

.field {
  .label {
    position: relative;
    top: -5px;
  }
}

.columns {
  margin-top: 0;

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .button {
      // margin: 10px 0 0 0px;
      min-width: 80px;
      background-color: #1a7ccb;
      color: #ffffff;
      cursor: pointer;
    }

    .info {
      border-radius: 50%;
      border: 1px solid silver;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 27px;
      min-height: 27px;
      width: 27px;
      height: 27px;

      .fa {
        color: #7c91ae;
      }
    }
  }

  #excludeShifts {
    textarea {
      min-width: 27px;
      min-height: 27px;
      width: 380px;
      height: 60px;
    }
  }

  .horizontal {
    display: flex;
    flex-direction: row;
    margin: 3px;
    padding: 3px;
  }

  .right-side {
    display: flex;
    align-items: flex-start;
    .recalculate-btn {
      margin: 10px 0 0 0;
    }
    .loading-recalculate {
      margin: 15px 10px 0 10px;
    }
    .info {
      border-radius: 50%;
      border: 1px solid silver;
      margin: 15px 0 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 27px;
      min-height: 27px;
      width: 27px;
      height: 27px;
      .fa {
        color: #7c91ae;
      }
      // .export-status-tooltip {
      //   // border-radius: 10px;
      //   border: 1px solid transparent;
      //   color: white;
      //   font-weight: 600;
      // }
    }
    .warn {
      border: 1px solid silver;
      .fa {
        color: #d9534f;
      }
    }

    .column {
      align-items: center;
      flex-direction: row;
      gap: 10px;
      align-items: space-between ;

      label {
        margin-bottom: 0;
      }
    }
  }
}
.revertBtn{
  // margin: auto;
  // margin-left: 0;
  // margin-bottom: 3px;
  margin-right: 50px!important;
}

.divider {
  margin-top: 40px;
  padding: 10px 0 20px 0;
  border-top: 2px solid $color--light-gray;
  border-bottom: 2px solid $color--light-gray;
  min-height: 150px;

  .breaker {
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $color--light-gray;
    right: 0;
    top: 35px;
  }
}
.control2 {
  button {
    background-color: #1a7ac9;
    height: 2.75em;
    border-color: transparent;
    color: #fff;
    border-radius: 3px;
    font-size: 0.75rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .breaker {
    display: none;
  }
}

.backup-fail {
  margin: 10px auto;
  width: 90%;
  text-align: center;
}

.backup-fail p {
  margin: 8px;
}

