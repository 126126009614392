
.dialog-container-multiple,
.dialog-container-single,
.dialog-container-success {
  text-align: center;
  .message {
    margin: 10px 0 10px 0;
    p {
      background-color: white;
    }
  }
}
.buttons button {
  cursor: pointer;
  margin: 0 5px 10px 5px;
}
