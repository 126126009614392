
.after-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 10px 0;
  .message {
    background-color: white;
    text-align: center;
  }
  .button {
    border: none;
    button {
      width: 60px;
      height: 2.75em;
      border: none;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
