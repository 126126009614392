
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .exit-modal-button {
        border-radius: 290486px;
        border: none;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        color: #fff;
        outline: none;
        transform-origin: center center;
        vertical-align: top;
        background: none;
        height: 40px;
        width: 40px;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 5000;
        transition: background-color .2s ease;
        background: rgba(68, 67, 67, 0.719);
        &:hover {
            background: rgba(68, 67, 67, 0.664);
        }
    }
    .modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .modal-content {
        position: absolute;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        width: 1100px;
        height: 92vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        overflow-x: auto;
        .spinner-wrap {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                width: 100px;
            }
        }
        .section {
            position: relative;
            top: 0;
            margin: 5px 0 0 0;
            width: 950px;
            padding: 5px;
            // display: flex;
            // justify-content: center;
            // max-height: 300px;
            // overflow: auto;
            // -ms-overflow-style: none;  /* IE and Edge */
            // scrollbar-width: none;  /* Firefox */
            // ::-webkit-scrollbar {
            //     display: none;
            // }
            border-top: 1px solid #00000034;
            // border-bottom: 1px solid #00000034;
            table {
                margin: 5px 0;
                border: 1px solid #9999998f;
                // border-collapse: collapse;
                border-collapse: separate;
                border-radius: 5px;
                table-layout: fixed;
                width: auto;
                table-layout: auto;
                td, th {
                    cursor: alias;
                    border: 1px solid #9999998f;
                    text-align: center;
                    vertical-align: middle;
                    height: 35px;
                    width: 120px;
                    input {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        border: none;
                        text-align: center;
                        outline: none;
                        &:hover {
                            background: #64626225;
                        }
                        &:disabled {
                            background: none;
                        }
                    }
                }
                .time-td {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    input {
                        text-align: center;
                        height: 100%;
                    }
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        appearance: none;
                        margin: 0;
                    }
                    input[type="number"]{
                        -moz-appearance: textfield;
                    }
                }
                input[type="text"]:disabled,
                input[type="number"]:disabled{
                    cursor: not-allowed;
                    -moz-appearance: textfield;
                }
                select {
                    outline: none;
                    border: none;
                    background: none;
                    width: 100%;
                    height: 25px;
                    margin: 0;
                    text-align: center;
                    &:hover {
                        cursor: pointer;
                        background: #00000010;
                    }
                }
                .delete-row {
                    text-align: center;
                    width: 80px;
                    i {
                        border-radius: 290486px;
                        &:hover {
                            cursor: pointer;
                            color: rgba(0, 0, 0, 0.534);
                        } 
                    }
                }
            }
            div {
                h3 {
                    margin: 0 auto;
                }
            }
        }
        .actions {
            margin: 10px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                margin: 0 5px;
            }
        }
        .shift-date-time-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 550px;
            div {
                margin: 3px 3px 3px 0;
            }
            input {
                width: 250px;
            }
        }
        .element-flex-wrapper {
            display: flex;
            align-items: center;
            width: 550px;
            input[type="checkbox"]{
                cursor: pointer;
            }
            input {
                width: 250px;
            }
            div {
                height: 100%;
                width: 50%;
                padding: 5px;
            }
        }
        .shift-break-wrapper {
            width: 550px;
            input {
                width: 250px;
                margin: 0 3px 0 0;
            }
        }
        textarea {
            border: 1px solid #9999998f;
            border-radius: 5px;
            width: 100%;
            max-width: 100%;
            height: 30px;
            max-height: 200px;
            margin: 0;
            outline: none;
            padding: 5px
        }
    }
}
