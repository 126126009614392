
.section {
  margin: 20px auto;
  .label {
    color: #7c91ae;
  }
  .section-wrapper {
    display: flex;

    > div {
      margin: 10px;
    }
  }
  .control {
    margin-bottom: 0;
  }
  textarea {
    border: 1px solid #9999994f;
    border-radius: 5px;
    width: 300px;
    max-width: 1000px;
    height: 100px;
    max-height: 1000px;
    margin: 0;
    outline: none;
    padding: 5px;
    &:hover {
      border: 1px solid #999999d3;
    }
    &:disabled {
      cursor: not-allowed;
      background: #0000002f;
      -moz-appearance: textfield;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .exit-modal-button {
    border-radius: 290486px;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    color: #fff;
    outline: none;
    transform-origin: center center;
    vertical-align: top;
    background: none;
    height: 40px;
    width: 40px;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 5000;
    transition: background-color 0.2s ease;
    background: rgba(68, 67, 67, 0.719);
    &:hover {
      background: rgba(68, 67, 67, 0.664);
    }
  }
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modal-content {
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 1100px;
    height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    overflow-x: auto;
  }
}
