
.cessation-date-wrapper {
  .pagi {
    margin: 0 0 10px 0;
    display: flex;
    justify-content: center;
  }
  .warn-msg {
    display: flex;
    justify-content: center;
    margin: 10px 250px 10px 250px;
    text-align: center;
    font-size: 1rem;
    flex-direction: column;
  }
  .cess-table {
    .table {
      tbody {
        .backgroundShift {
          background-color: #dd7e7e2f;
        }
      }
    }
  }
  .cess-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0 5px 0;
    gap: 10px;
  }
}
