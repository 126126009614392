
.loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  // .loading-wrapper {
  // height: 20px;
  // width: 35px;
  // padding: 0 0 0 10px;
  // }
}
