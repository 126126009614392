
.modal {
    .modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .modal-content {
        z-index: 5000000;
        position: absolute;
        background-color: white;
        padding: 15px;
        border-radius: 5px;
        width: 350px;
        height: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.03em;
        overflow: auto;
        .message-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
            height: 230px;
            width: 100%;
            padding: 3px;
            p {
                width: 95%;
                text-align: center;
            }
        }
        button {
            margin: 0 5px
        }
    }
    .exit-modal-button {
        border-radius: 290486px;
        border: none;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        color: #fff;
        outline: none;
        transform-origin: center center;
        vertical-align: top;
        background: none;
        height: 40px;
        width: 40px;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 5001;
        transition: background-color .2s ease;
        background: rgba(68, 67, 67, 0.404);
        &:hover {
            background: rgba(68, 67, 67, 0.664);
        }
    }
}
