
.shift-table-comp {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
span {
  font-weight: 400;
}

.shift-type-td {
  color: #aeafb7;

  img {
    max-width: 27px;
    max-height: 27px;
    width: auto;

    &.onCall {
      $width: 40px;
      width: $width;
      max-width: $width;
      max-height: $width;
      margin-bottom: -5px;
      margin-left: -7px;
    }
  }
}

em {
  color: #333;
}

svg {
  margin-left: 0.5em;
  cursor: pointer;

  // *:not(:first-child) {
  //   margin-left: 0.5em;
  // }
}

.filter_dropdown {
  z-index: 2;
  position: absolute;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 5px 0 rgba(black, 0.3);
  left: 0;
  background-color: white;
  padding: 1em;
}
.set-width {
  display: flex;
  width: 140px;
  text-align: center;
}
.column-min {
  flex: none;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 0.75rem;
}
.arr-status {
  width: 90px;
  display: flex;
  flex-wrap: wrap !important;
  text-align: center;
  min-height: 70px;
  padding: 0;
  // background-color:red;
  justify-content: center;
  align-items: center;
}
.is-narrow {
  display: flex;
  align-items: center;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.6em 0.5em;
}
.fix-min-width {
  min-width: 200px;
}
.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;

  img {
    width: 50px;
  }
}

.button-wrap {
  display: flex;
  gap: 10px;
}

// Fixes table headers with sort buttons layout.
th,
.flex-header.column-label {
  font-size: 13px;
}

.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .column-label {
    flex: 0 1;
  }

  .sort-buttons {
    flex: 1 0 auto;
    margin-left: 3px;
  }
}
