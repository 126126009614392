
.table-wrap {
  display: flex;
  overflow: auto;
  max-height: calc(100vh - 400px);

  .table {
    // tr.private {
    //   background: #f7f7f7;
    // }

    &.sticky {
      border-collapse: separate;
      border-spacing: 0;
    }
  }

  .total-value {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
