
.modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  gap: 10px;
  min-height: 150px;
  justify-content: space-between;
  width: 350px;

  .input-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    label {
      min-width: 120px;
    }

    input {
      height: 40px;
    }

    &.date {
      :deep() {
        input {
          height: 40px;
        }
      }
    }

    &.desc {
      input {
        width: 100%;
      }
    }

    &.amount {
      width: 300px;
    }
  }

  .action-group {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
  }
}
