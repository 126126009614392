
#paybill-invoices-balances {
  .columns {
    padding: 10px 10px 0;

    .multiselect {
      width: 300px;
    }
  }

  > .inner-content {
    .multiselect {
      width: 300px;
    }
  }

  .right {
    margin-left: auto;
  }

  button {
    display: flex;
    gap: 10px;
  }

  .disabled {
    opacity: 0.5;
  }

  .field {
    display: flex;
    margin-bottom: 30px;
    gap: 10px;
    align-items: center;

    label {
      margin: 0 1em;
    }
  }

  .label {
    color: #7c91ae;
  }
}
