
.confirm-modal {
  display: flex;
  flex-direction: column;
  .message {
    //   display: flex;
    //   justify-content: space-between;
    text-align: center;
    margin: 10px 0 0 0;
    background: transparent;
  }
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    button {
      margin: 0 10px;
    }
  }
}
