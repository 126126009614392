
#client-invoice-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;

  .pending-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    // max-height: 640px;
    padding: 10px 0;
    overflow: auto;
  }

  .row {
    display: flex;
    // align-items: center;
    gap: 10px;

    .control-row {
      display: flex;
      flex-direction: column;
    }

    .input {
      height: 40px;
    }

    .address-id {
      input {
        width: 150px;
      }
    }

    .name-field {
      input {
        min-width: 170px;
      }
    }

    .subcat-field {
      width: 250px;
    }

    .location-field {
      width: 250px;
    }

    .remove-action {
      margin-left: 10px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s ease all;
      align-self: center;

      &:hover {
        background: lightcoral;
        color: white;
      }
    }
  }

  .separator {
    width: 100%;
    border-top: 1px solid silver;
  }
}
