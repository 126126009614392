
.stickerComponent{
    min-width: 34px !important;
    width: fit-content !important;
    height: 15px !important;
    border-radius: 2px;
    padding: 10px 4px 10px 4px!important;

    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 0em !important;
    text-align: center !important;

}

