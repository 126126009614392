
.dialog-container {
  width: 100%;
  overflow: hidden;

  .recalculation-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin: 10px 0 0 0;
    .message {
      font-size: 1rem;
      background-color: #ffffff;
      text-align: center;
    }
    .succesMessage {
      font-size: 1rem;
      color: #5cb85c;
      transition: 2s;
    }
    .errMessage {
      font-size: 1rem;
      color: #d9534f;
      transition: 2s;
    }
    // padding: 0 1.3em;

    label {
      margin-top: 0;
    }
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

table.no-border {
  border: 0px;
}

.btn-loader {
  height: 18px;
  padding-right: 4px;
}
