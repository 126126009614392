
.shift-schedule-list {
  .pagination {
    align-self: flex-end;
  }
}

.shift-filters {
  display: flex;
  margin-bottom: 19px;
  flex-wrap: wrap;
  align-items: flex-end;

  .field {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .shift-datetime {
    margin-left: 10px;

    ::v-deep {
      label {
        font-size: 20px !important;
      }

      input {
        line-height: 30px !important;
        font-size: 18px !important;
      }
    }
  }

  .predefined-date {
    &::v-deep input {
      background-color: rgb(253, 253, 180);
    }
  }

  .shift-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;

    .label {
      margin: 0 0 0.5em 0;
    }
  }

  .reset-filters {
    margin-right: auto;
  }
}

.shift-datetime {
  .label {
    float: left;
    position: relative;
    top: 7px;
  }

  .control {
    float: left;
  }
}

.spinner-wrap {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.info-tooltip {
  border-radius: 50%;
  border: 1px solid silver;
  margin: 0 0 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 27px;
  min-height: 27px;
  width: 27px;
  height: 27px;

  .fa {
    color: #7c91ae;
  }
}

@media screen and (max-width: 490px) {
  .field:first-child {
    p {
      margin-left: auto;
    }
  }

  .field {
    width: 100%;
    justify-content: space-between;
  }
}
