
.custom-animation-enter,
.custom-animation-leave-to {
  transform: scale(0);
}

.custom-animation-enter-active,
.custom-animation-leave-active {
  transition: all 0.3s ease-out, opacity 1s ease-out;
}

table.no-radius {
  border-radius: 0;

  td {
    background-color: #f2f7fa;
  }
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

tbody.generic-app-subtable {
  z-index: 500;

  tr {
    background-color: #f2f7fa;

    td.location-allowance-td,
    td.secure-allowance-td,
    td.community-allowance-td {
      text-align: center;
      // padding: 0 3% 0 4%;
      .loader {
        border: 5px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .subtable-name {
    width: 15%;
    padding-left: 50px;
    text-overflow: ellipsis;
    max-width: 410px;
    overflow: hidden;

    > .right-side {
      // display: inline;
      float: left;
      margin-right: 5px;

      svg {
        cursor: pointer;
      }

      button {
        height: 20px;
        background: transparent;
        border: none;
      }
    }
  }
  
}
.external_id{
    padding-left: 0.5em !important;
}

.wrapper-activation {
  text-align: right;
}

.block.controls {
  button {
    margin-right: 10px;
  }
}
