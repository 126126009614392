
$gray-color: #7c7d86;
$tab-color: var(--color-primary-10);

// $dark-gray: $gray-color;
$grad-color: #2d2f30;
$background-color: transparent;

// $grad-color: #28292a; // Maybe this
// $grad-color: #25282a;

.uni-tabs-wrap {
  // --tab-background: var(--canvas-background);
  $tab-text-color: #7c7d86;
  $active-tab-text-color: var(--color-primary-10);

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 5px 0 0;
  width: 100%;

  @mixin color-tab {
    color: $tab-color !important;

    .line {
      background-color: $tab-color !important;
    }
  }

  .line-wrapper {
    display: flex;
    height: 4px;
    width: 100%;

    &.top {
      margin-bottom: 5px;
    }

    &:not(&.top) {
      margin-top: 5px;
    }

    .line {
      position: relative;
      height: 4px;
      transition: all 0.3s ease;
      background-color: $tab-color !important;
    }
  }

  .uni-tabs {
    user-select: none;
    align-items: stretch;
    font-size: 1rem;
    font-weight: bold;
    justify-content: space-between;
    width: 100%;

    // overflow: hidden;
    // overflow-x: auto;
    white-space: nowrap;
    position: relative;
    display: inline-flex;
    height: 100%;

    .tab {
      $icon-size: 13px;

      // min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 10px;
      outline: none;
      white-space: nowrap;
      position: relative;
      font-size: 1rem;
      color: $tab-text-color;
      height: 100%;
      width: 100%;
      gap: 5px;
      text-decoration: none;
      cursor: pointer;

      &:not(.interactable) {
        pointer-events: none;
      }

      // &:not(:last-child) {
      //   border-right: 1px solid $medium-gray;
      // }

      .line {
        height: 3px;
        width: 100%;
        background-color: $gray-color !important;
      }

      .row {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
        height: 100%;
        margin: auto;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          .node-image {
            margin: auto 7px;
            min-width: 12px;
            max-width: 12px;
            height: 12px;
            z-index: 1;
          }
        }

        .content-part {
          display: flex;
          align-items: center;
          gap: 5px;

          .name,
          .label {
            min-width: 30px;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            user-select: none;
            font-weight: 800;
            margin-bottom: -1px;
          }

          .other {
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
              max-width: $icon-size;
              max-height: $icon-size;
              width: 100%;
              height: 100%;
            }

            .dirty,
            .locked,
            .error {
              color: var(--app-accent-color);
              display: flex;
              max-width: $icon-size;

              // max-height: $icon-size;
            }

            .locked {
              display: flex;
              justify-content: center;
              align-items: center;

              // svg {
              //   filter: invert(0.8);
              // }
            }

            .close-wrap {
              display: flex;
              transition: opacity 0.2s ease;
              opacity: 0.3;
              width: 22px;

              @media screen and (width >= 768px) {
                opacity: 0;
              }

              @media (hover: none) and (pointer: coarse) {
                opacity: 0.3;
              }
            }

            // .faico {
            // 	&:hover {
            // 		transition: color 0.3s ease;
            // 		color: var(--app-accent-color);
            // 	}
            // }
          }
        }
      }

      &.pinned {
        padding: 0 7px 1px;
      }

      &.inactive {
        opacity: 0.4;
      }

      &.tab-active {
        // background-color: var(--tab-background);

        background-color: transparent;

        // background-color: rgba($background-color, 0.95);
        // backdrop-filter: blur(2px);

        // box-shadow: inset 0 10px 15px 0 rgba($background-color, 0.4);

        .row {
          .name,
          .label {
            // mix-blend-mode: exclusion;
            color: $active-tab-text-color;
          }
        }

        &:hover {
          background-color: lighten($background-color, 2);
          font-weight: 800;
        }
      }

      // &:not(.tab-active) {
      // 	background-color: rgba($background-color, 0.95);
      // 	backdrop-filter: blur(10px);
      // }

      &:hover {
        transition: all 0.2s ease;
        background-color: lighten($background-color, 2);

        .row {
          .content-part {
            .close-wrap {
              opacity: 1;
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      height: 0.7em;
    }
  }

  &:not(.modern) {
    .line-wrapper {
      background-color: $gray-color;
    }
  }

  .cut-slide-enter-active,
  .cut-slide-leave-active {
    transition: all 0.1s;
  }

  .cut-slide-enter-from {
    opacity: 0;
    transform: translateX(-40px);
  }

  .cut-slide-leave-to {
    opacity: 0;
    transform: translateX(-30px);
  }
}
