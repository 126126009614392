
.invoices-billing-period {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 60vh;
  max-width: 300px;
  width: 100%;
  margin: auto;
  padding: 0 5px;
  overflow: auto;

  .item {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }
}
