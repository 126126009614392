
#client-profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .form {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    overflow: auto;
    height: 100%;

    .left-side,
    .right-side {
      width: 100%;
      max-width: 350px;

      .client-and-ref-id {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .control {
          margin-bottom: 0;
        }

        .field {
          width: 165px;
          margin: 10px 0 0 0;

          .label {
            // inline-size: 125px;
            text-align: left;
          }
        }
      }
    }

    .client-reference-wrap {
      display: flex;
      gap: 5px;
      align-items: flex-end;

      .client-ref-id {
        width: 60%;
      }
    }
  }

  input {
    height: 40px;
  }
}
