
.modal {
  label {
    margin-top: 0.5em;
    color: #7c91ae;
  }
}

.modal-content {
  display: flex;
  overscroll-behavior: none;
}

.modal-title {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-left: 0.4em;
}

.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh; // This is to prevent overflow scrolling

  // justify-content: center;
  // align-items: center;

  // .wrapp-panel-content {
  //   min-height: fit-content;
  // }
  .panel-heading {
    margin: auto 0 0 0; // This is to center child
  }

  .panel-block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    padding: 0 1.3em;
    justify-content: center;
    // overflow: auto;
    margin: 0 0 auto 0; // This is to center child
  }

  .has-text-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panel-heading {
    background-color: white;
  }

  button {
    align-self: flex-end;
  }
}
