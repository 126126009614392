
.schedule-tabs {
  section {
    &.main-listing-section {
      display: flex;
      flex-direction: column;
      height: 100%;

      .shift-schedule-list {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 0 20px 0;

        :deep() {
          .shift-table-comp {
            height: 100%;
          }
        }
      }
    }
  }
}
