
$lightgray: #7c91ae;
$border-color: #9bb4c1;

.edit-profile-wrap {
  width: 100%;
  form.mt {
    p.control.tss-wtd {
      display: flex;

      > * {
        width: 100%;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .nickname-wrap {
      display: flex;

      > * {
        width: 100%;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }

      .contract-start {
        width: 250px;

        .action {
          display: flex;
          gap: 5px;
          align-items: center;

          :deep() {
            input {
              border-color: #9bb4c1;
            }
          }

          .fa {
            cursor: pointer;

            &:hover {
              color: silver;
            }
          }
        }
      }
    }

    .label {
      color: $lightgray;
      font-size: 12px;
    }
    .input {
      box-sizing: border-box;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  .input-info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0.75rem;
  }
}
