/* .generic-app-blue {
  color: var(--color-primary);
} */

@import "./tooltip.scss";

.title,
.subtitle {
  color: var(--color-secondary-50);

  i {
    margin-right: 5px;
  }

  i[class^="fa fa"] {
    position: relative;
    top: 5px;
  }
}

.input.is-medium,
.textarea.is-medium {
  font-size: 1.1rem;
}

input {
  &.is-error {
    background-color: #f3a8a836;
    border: 1px solid #de94a3;
  }
}

.error-text {
  color: #e24f4f;
}

.is-orange-btn {
  background-color: orange;
}

.is-green-btn {
  background-color: #23d160;
  color: white !important;
}

.is-gray-btn {
  background-color: #6d6d6d;
  color: white !important;
}

.is-lime-green {
  background-color: #a8bc46;
  color: black !important;
}

.is-generic-app-teal {
  background-color: var(--color-primary-40) !important;
  color: white !important;
}

.is-generic-app-light-blue {
  background-color: var(--color-primary-20) !important;
  color: white !important;
}

// Table (sticky)
table.sticky {
  border-top: unset;
  border-collapse: separate;
  border-spacing: 0;

  th {
    position: sticky;
    top: 0;
    border-top: 1px solid #dde6eb;
    z-index: 1;
  }

  thead {
    td {
      //  If not using th
      position: sticky;
      background-color: white;
      top: 0;
      border-top: 1px solid #dde6eb;
      z-index: 1;
    }
  }
}

/*
    * Book Shifts table
    */
.shifts-table {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: hidden;

  .shifts-table--body {
    height: 150px;
    background: #f0f6f6;
    padding: 5px;
    overflow: auto;
  }

  .shifts-table--header {
    padding: 0 5px;

    .shift-row {
      border: 1px solid transparent !important;
    }
  }

  .remove-shift-btn {
    border-radius: 50%;
    color: #fff;
    width: 21px;
    height: 21px;
    border: none;
    cursor: pointer;
    text-align: center;
  }

  .shift-row {
    height: 30px;
    background: #fff;
    margin-bottom: 5px;
    display: flex;
    border: 1px solid transparent;
    border-radius: 7px;

    &:hover {
      border: 1px solid #60b6fd;
    }

    .shift-cell {
      flex: 1;
      padding: 4px;
      justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;

      &.id {
        min-width: 10%;
        max-width: 10%;
        color: #60b6fd;
      }

      &.date {
        min-width: 100px;
        max-width: 25%;
      }

      &.time {
        min-width: 100px;
        max-width: 25%;
      }

      &.total-hours {
        min-width: 90px;
        max-width: 25%;

        .input {
          height: 100%;
          padding-right: 0;
          width: 67px;
        }
      }

      &.on-call {
        min-width: 90px;
        max-width: 25%;
      }

      &.req-shift {
        min-width: 200px;
        max-width: 200px;

        .input {
          height: 100%;
          padding-right: 0;
          width: 67px;
        }
      }

      &.button-cell {
        max-width: 50px;
      }
    }
  }
}

.control.has-addons .button {
  color: var(--color-primary) !important;
  border-color: rgb(219, 219, 219) !important;
}

// Vars
$background-increment: rgba(77, 163, 255, 0.6);
$background-increment-hover: rgba(100, 196, 255, 0.7);
$text-color: #405168;

.blue-button-action {
  border-radius: 4px;
  background-color: $background-increment;
  width: 185px;
  color: $text-color;
  font-size: 12px;
  font-weight: bold;
  padding: 0 0 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  justify-content: space-between;
  padding: 0;

  span {
    text-overflow: ellipsis;
    overflow: hidden;

    &.label {
      color: inherit;
      padding: 5px 0 5px 10px;
      margin-bottom: 0;
    }

    &.inc {
      padding: 5px 10px;
      display: inline-block;
      text-align: center;
      font-weight: bold;
      border-radius: 0;
      border: 1px solid $background-increment;
      background-color: white;
      // float: right;
      max-width: 35%;
      min-width: 25%;
    }
  }
}
.blue-button-action::after {
  clear: both;
}
.blue-button-action:hover {
  background-color: $background-increment-hover;
  cursor: pointer;
}

// Switcher
// .vue-switcher-theme--bulma.vue-switcher-color--blue.vue-switcher--unchecked
//   div {
//   background-color: var(--color-primary-60);
//   filter: saturate(0.5);
// }

// .vue-switcher-theme--bulma.vue-switcher-color--blue div {
//   background-color: var(--color-primary-90);
// }

// .vue-switcher-theme--bulma.vue-switcher-color--blue.vue-switcher--unchecked
//   div:after {
//   background-color: var(--color-primary-30);
//   filter: saturate(0.5);
// }

// .vue-switcher-theme--bulma.vue-switcher-color--blue div:after {
//   background-color: var(--color-primary-d10);
// }

// v-tooltip classes
.tooltip-width-600 {
  max-width: 600px;
}

.tooltip-width-400 {
  max-width: 400px;
}

.tooltip-width-300 {
  max-width: 300px;
}

.tooltip-centered-text {
  text-align: center;
}

.modern-ui-22 {
  background-color: transparent;

  .table {
    $border-radius: 8px;
    font-size: 16px;
    border: 0;

    thead {
      tr {
        th {
          background-color: #eff2f5;
          color: #173d6e;
          border: 0;
          padding: 0.9em 0.75em;

          span {
            font-weight: bold;
            user-select: none;
            letter-spacing: 0.03em;
            cursor: default;
          }
        }

        // th:first-child {
        //   border-top-left-radius: $border-radius;
        // }

        // th:last-child {
        //   border-top-right-radius: $border-radius;
        // }
      }
    }

    tbody {
      tr {
        td {
          border: 0;
          border-bottom: 1px solid #e2e7ea;
          padding: 0.56em 0.75em;

          span {
            font-weight: bold;
          }
        }

        // &:nth-child(even) {
        //   .generic-app-tag {
        //     background-color: silver;
        //     // box-shadow: 0 0 5px silver;
        //   }
        // }
      }
    }

    button {
      text-transform: none;
    }
  }
}

.page-header-section {
  display: flex;

  h1.title {
    margin-bottom: 0.3em;
    cursor: default;
  }

  > * {
    flex-basis: 33%;
  }
}
