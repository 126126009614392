
.token-verification-wrapper {
  margin: 20px 0 0 0;
  padding: 10px 20px;
  .active {
    color: red;
  }
  .verification-message {
    color: #4ad862;
    margin: 0 auto;
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
  }
}

@media screen and (max-width: 1500px) {
  .token-verification-wrapper {
    .verification-message {
      font-size: 25px;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 500px) {
  .token-verification-wrapper {
    margin: 10px 0 0 0;
    padding: 5px 10px;
    .title.is-3 {
      font-size: 1rem;
    }
    .verification-message {
      font-size: 20px;
    }
  }
}
